import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import sowPigCyclePreferenceRepository from '@/repositories/sow-pig-cycle-preference.repository'

const [vars, varNames] = requestVars({ identifier: 'sow-pig-cycle-preference', data: 'sowPigCyclePreferenceData' })

const handler = new VueRequestHandler(null, varNames)

const data = () => ({
  ...vars,
})

const methods = {
  indexSowPigCyclePreference (params) {
    const repository = sowPigCyclePreferenceRepository.index
    handler.setVue(this)
    handler.execute(repository, [params])
  },
}

export default {
  data,
  methods,
}