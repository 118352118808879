<template lang="pug">
  div
    v-row
      v-col(cols="12")
        v-text-field(
          label="Stock Category"
          :value="stockCategory.name"
        )
      v-col(cols="12")
        v-text-field(
          label="Service"
          type="number"
          v-model="service"
        )
      v-col(cols="12")
        v-text-field(
          label="Farrowing"
          type="number"
          v-model="farrowing"
        )
      v-col(cols="12")
        v-text-field(
          label="Weaning"
          type="number"
          v-model="weaning"
        )
    v-btn(
      color="primary"
      dark
      dense
      small
      @click="saveSowPigData"
      :loading="sowPigCyclePreferencePosting"
    )
      span Save
</template>
<script>
import IndexSowPigCyclePreferences from '@/components/mixins/IndexSowPigCyclePreference'
import UpdateSowPigCyclePreferences from '@/components/mixins/UpdateSowPigCyclePreference'

const inputVars = () => ({
  id: null,
  service: null,
  farrowing: null,
  weaning: null,
  stockCategoryId: null,
  stockCategory: {},
})

export default {
  name: 'SowPigCyclePreferences',
  data: () => ({
    ...inputVars(),
  }),
  created () {
    this.getSowPigCyclePreferences()
  },
  watch: {
    sowPigCyclePreferenceData (data) {
      this.populateInputs(data)
    },
  },
  methods: {
    getSowPigCyclePreferences () {
      const params = {
        with: ['stockCategory'].join(','),
      }
      this.indexSowPigCyclePreference(params)
    },
    initializeWebsocket () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbUpdateEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      if (model === 'SowPigCyclePreference') {
        this.sowPigCyclePreferenceDBUpdateEvent(data)
      }
    },
    sowPigCyclePreferenceDBUpdateEvent (data) {
      this.sowPigCyclePreferenceData = data
    },
    populateInputs (data) {
      const { objectChangeKeyCase } = Window
      data = objectChangeKeyCase(data)
      Object.keys(data).forEach(key => {
        this[key] = data[key]
      })
    },
    saveSowPigData () {
      const { id, data } = this.getInputFields()
      this.$confirm('Are you sure you want to save changes')
        .then(confirm => {
          if (!confirm) return
          this.updateSowPigCyclePreference(id, data)
        })
    },
    getInputFields () {
      const excluded = ['id', 'stockCategoryId']
      const { id } = this
      const data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
      return { id, data }
    },
  },
  mixins: [IndexSowPigCyclePreferences, UpdateSowPigCyclePreferences],
}
</script>