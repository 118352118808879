import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import sowPigCyclePreferenceRepository from '@/repositories/sow-pig-cycle-preference.repository'

const [vars, varNames] = requestVars({ identifier: 'sow-pig-cycle-preference', hasData: false, request: 'post' })

const handler = new VueRequestHandler(null, varNames)

const data = () => ({
  ...vars,
})

const methods = {
  updateSowPigCyclePreference (id, data) {
    const repository = sowPigCyclePreferenceRepository.update
    handler.setVue(this)
    handler.execute(repository, [id, data])
  },
}

export default {
  data,
  methods,
}