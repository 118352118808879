import repository from './repository'

const resource = 'sow-pig-cycle-preferences'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
}
